<template>
  <div class="pa-6 d-flex flex-column align-center">
    <v-tabs v-model="tab" background-color="background">
      <v-tab key="export"> Export</v-tab>
      <v-tab key="upload"> Upload matches</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="fill-width pa-4">
      <v-tab-item key="export">
        <h1 class="text--text">Export ritual data</h1>
        <v-row class="gap-4 ml-0">
          <v-switch v-model="notMatched" dense label="Declined/Not-matched only" />
          <v-switch v-model="lastRound" :disabled="!notMatched" dense label="Last round only" />
        </v-row>
        <div class="d-flex flex-row">
          <v-btn @click="loadData">Reload</v-btn>
          <v-btn :disabled="!raw" class="ml-auto" @click="save">Save</v-btn>
        </div>
        <v-switch v-model="preview" :disabled="!raw" class="mr-auto" dense label="Show content" />
        <m-text-area :value="formattedContent" class="fill-width" readonly />
      </v-tab-item>
      <v-tab-item key="upload">
        <h1 class="text--text mb-4">Upload ritual matches</h1>
        <v-row class="align-center">
          <v-file-input
            v-model="matches.file"
            accept="application/json"
            dense
            hide-details
            outlined
            placeholder="Pick a file"
          />
          <v-btn :disabled="!matches.file" class="ml-8" @click="processMatchesFile">Process</v-btn>
        </v-row>
        <v-row class="my-4">
          <m-text-area :value="matches.content" class="fill-width" />
        </v-row>
        <v-row class="pa-4">
          <v-switch v-model="rematch" class="ml-auto" label="Rematch (For periodic matches)" />
        </v-row>
        <v-row class="pa-4">
          <v-btn :disabled="!matches.content" class="ml-auto" @click="uploadMatches">Upload</v-btn>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { LoadRitualData, UploadRitualResults } from '@/store/rituals';
import MTextArea from '@/components/Inputs/MTextArea/MTextArea.vue';

function readFileAsync(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export default Vue.extend({
  name: 'm-ritual-data',
  components: { MTextArea },
  props: {
    ritualId: Number,
  },
  data: () => ({
    notMatched: false,
    lastRound: false,
    raw: undefined,
    preview: false,
    tab: undefined,
    matches: {
      file: undefined,
      content: undefined,
      data: undefined,
    },
    rematch: false,
  }),
  computed: {
    formattedContent() {
      if (!this.preview) return '';
      return this.raw ? JSON.stringify(this.raw, undefined, 4) : '';
    },
  },
  methods: {
    async loadData() {
      const content = await this.$store.dispatch(
        new LoadRitualData(
          { id: this.ritualId },
          {
            notMatched: this.notMatched,
            lastRound: this.lastRound,
          },
        ),
      );
      if (content) {
        this.raw = content;
      }
    },
    async save() {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(this.raw)], {
          type: 'text/plain',
        }),
      );
      a.setAttribute('download', 'data.json');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async processMatchesFile() {
      const content = await readFileAsync(this.matches.file);

      this.matches.data = JSON.parse(content);
      this.matches.content = JSON.stringify(this.matches.data, undefined, 2);
    },
    async uploadMatches() {
      let matches, withoutMatch, closeMatches;
      if (Array.isArray(this.matches.data)) {
        matches = this.matches.data;
      } else {
        matches = this.matches.data['matches'];
        withoutMatch = this.matches.data['withoutMatch'];
        closeMatches = this.matches.data['closeMatches'];
      }
      await this.$store.dispatch(
        new UploadRitualResults({ id: this.ritualId }, matches, withoutMatch, this.rematch, closeMatches),
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.m-user-image {
  border-radius: 50%;
  margin-right: 5px;
}

.m-matches-container {
  width: 90%;
  max-width: 800px;
}
</style>
